@charset "utf-8";

/*======================================= CAROUSEL AND TESTIMONIAL SLIDER START ====================================================================*/

#owl-demo1 .item,
#owl-demo .item {
  background-color:transparent;
  margin: 0px 10px;
  color: #FFF;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}

.customNavigation{
  text-align: center;
}

/*use styles below to disable ugly selection*/
.customNavigation a{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
#owl-demo1 .owl-controls .owl-page span,
.owl-theme .owl-controls .owl-page span  {
	display:none !important;
}*/

/*======================================= CAROUSEL AND TESTIMONIAL SLIDER END ====================================================================*/



