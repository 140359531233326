#owl-demo1 .item, #owl-demo .item {
  color: #fff;
  text-align: center;
  background-color: #0000;
  border-radius: 3px;
  margin: 0 10px;
}

.customNavigation {
  text-align: center;
}

.customNavigation a {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: #0000;
}
/*# sourceMappingURL=index.fd72e7ab.css.map */
